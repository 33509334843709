import React from 'react'
import Box from '../../Box'

export default ({
  title,
  children,
}) => (
  <Box
    paddingY={2}
  >
    <Box
      fontSize={18 / 16}
      color='grey4'
      textTransform='uppercase'
      paddingBottom={1}
    >
      {title}
    </Box>
    <Box>
      {children}
    </Box>
  </Box>
)
