import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/lt'

moment.locale('lt')

const groupByDate = ({
  posts,
}) => (
  _.groupBy(posts, ({
    node: {
      date,
      _meta: {
        firstPublicationDate,
      },
    },
  }) => (
    moment(date || firstPublicationDate).startOf('month').toISOString()
  ))
)

const postDate = ({
  node: {
    date,
    _meta: {
      firstPublicationDate,
    },
  }
}) => (
  +moment(date || firstPublicationDate)
)

const orderPosts = ({
  posts,
}) => (
  _.orderBy(posts, [postDate], ['desc'])
)

const groupedPosts = ({
  posts,
}) => (
  _.map(groupByDate({ posts }), (posts, date) => ({
    posts: orderPosts({ posts }),
    date,
  }))
)

const groupDate = ({ date }) => (
  +moment(date)
)

export default ({
  posts,
}) => (
  _.orderBy(groupedPosts({ posts }), [groupDate], ['desc'])
)
