import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/lt'
import scale from '../Landing/Splash/lib/scale'
import Layout from '../Layout'
import FullWidthLayout from '../FullWidthLayout'
import Box from '../Box'
import PostItem from '../PostItem'
import NewestPost from './NewestPost'
import Group from './Group'
import groups from './lib/groups'

moment.locale('lt')

export default ({
  edges,
  title,
}) => {
  const [newestPost, ...postsRest] = edges

  return (
    <Layout>
      <FullWidthLayout>
        <Box
          paddingTop={8}
          paddingX={{ xs: 1, lg: 2 }}
        >
          <Box
            fontSize={scale(24, 46)}
            paddingTop={6}
            paddingBottom={2}
            fontWeight={500}
            color='grey4'
            fontFamily='Noto Nastaliq Urdu'
            wordBreak='break-word'
          >
            {title}
          </Box>

          {newestPost && <NewestPost post={newestPost.node} />}

          {_.map(groups({ posts: postsRest }), ({ posts, date }, i) => (
            <Group key={i} title={moment(date).format('MMMM YYYY')}>
              <Box
                display='grid'
                gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr 1fr' }}
                gridGap='64px'
              >
                {_.map(posts, ({ node }) => (
                  <PostItem
                    key={node._meta.uid}
                    post={node}
                  />
                ))}
              </Box>
            </Group>
          ))}
        </Box>
      </FullWidthLayout>
    </Layout>
  )
}
