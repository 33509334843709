import React from 'react'
import { Link } from 'gatsby'
import Video from '../../../assets/video.svg'
import Box from '../../Box'
import Background from '../../Background'
import Content from '../../PostItem/Content'
import Group from '../Group'

export default ({
  post: {
    is_video: isVideo,
    image,
    imageSharp,
    _meta: {
      uid,
    },
  },
  post,
}) => (
  <Group title='Naujausias'>
    <Box
      display='flex'
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <Box
        display='flex'
        component={Link}
        to={`/posts/${uid}`}
        position='relative'
        width={{ xs: '100%', lg: '60%' }}
        flexShrink={0}
        height={20}
      >
        <Background
          display='flex'
          flexGrow={1}
          image={image}
          imageSharp={imageSharp}
          backgroundRepeat='no-repeat'
          backgroundSize='cover'
          backgroundPosition='center'
          borderRadius={4 / 16}
          overflow='hidden'
        />
        {isVideo && (
          <Box
            position='absolute'
            bottom={-1}
            right={-1}
            height={3}
            width={3}
            component={Video}
          />
        )}
      </Box>
      <Box paddingLeft={{ xs: 0, lg: 2 }} paddingTop={{ xs: 1, lg: 0 }}>
        <Content post={post} />
      </Box>
    </Box>
  </Group>
)
